
import { defineComponent, getCurrentInstance, ref } from "vue";
import { hideModal } from "@/sea/utils/Dom";

export default defineComponent({
  name: "confirmation-modal",
  emit: ["ok-click"],
  props: {
    okButtonStyle: { type: String, default: "btn-primary" },
    noButtonStyle: { type: String, default: "btn-light" },
  },
  setup(props, { emit }) {
    const vDom = ref<HTMLElement | null | undefined>(null);

    const vNodeProps = getCurrentInstance()?.vnode.props || {};

    const okClick = () => {
      hideModal(vDom.value);
      if ("onOkClick" in vNodeProps) {
        emit("ok-click");
      }
    };
    return {
      vDom,
      okClick,
    };
  },
});
