import { ref } from "vue";
import {
  getDataRequest,
  getListRequest,
  patchDataRequest,
} from "@/sea/apis/BaseService";
import { BranchResponse } from "@/sea/apis/models/branch/BranchResponse";

export const getBranchesRequest = () => {
  const {
    responseRef,
    errorRef,
    listRef,
    totalRef,
    pageNumberRef,
    pageSizeRef,
    getList,
  } = getListRequest<BranchResponse>();
  const branchesRef = ref<Array<BranchResponse> | null | undefined>([]);

  const getBranches = async (
    name: string | null,
    isOpen: boolean | null,
    isEnabled: boolean | null,
    sort: string | null,
    pageNumber: number,
    pageSize: number
  ) => {
    await getList(true, "/branches", {
      params: {
        name: name,
        isOpen: isOpen,
        isEnabled: isEnabled,
        sort: sort,
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
    });
    branchesRef.value = listRef.value;
  };

  return {
    responseRef,
    errorRef,
    branchesRef,
    totalRef,
    pageNumberRef,
    pageSizeRef,
    getBranches,
  };
};

export const getBranchRequest = () => {
  const { responseRef, errorRef, dataRef, getData } =
    getDataRequest<BranchResponse>();
  const branchRef = ref<BranchResponse | null | undefined>(null);

  const getBranch = async (branchId: number) => {
    await getData(true, `/branches/${branchId}`);
    branchRef.value = dataRef.value;
  };

  return { responseRef, errorRef, branchRef, getBranch };
};

export const patchBranchRequest = () => {
  const { responseRef, errorRef, dataRef, patchData } =
    patchDataRequest<BranchResponse>();
  const branchRef = ref<BranchResponse | null | undefined>(null);

  const patchBranch = async (branchId: number, data: unknown) => {
    await patchData(true, `/branches/${branchId}`, data);
    branchRef.value = dataRef.value;
  };

  return { responseRef, errorRef, branchRef, patchBranch };
};
