
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute, useRouter } from "vue-router";
import {
  getBranchesRequest,
  patchBranchRequest,
} from "@/sea/apis/BranchesService";
import { I18nUtils } from "@/sea/utils/I18nUtils";
import { UserUtils } from "@/sea/utils/UserUtils";
import { DateTimeUtils } from "@/sea/utils/DateTimeUtils";
import { StringUtils } from "@/sea/utils/StringUtils";
import Datatable from "@/sea/components/datatables/Datatable.vue";
import { useI18n } from "vue-i18n/index";
import { showModal } from "@/sea/utils/Dom";
import ConfirmationModal from "@/sea/components/modals/ConfirmationModal.vue";
import { useToast } from "vue-toastification";
import { reinitializeComponents } from "@/core/plugins/keenthemes";

export default defineComponent({
  name: "branches",
  components: {
    ConfirmationModal,
    Datatable,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { locale } = useI18n();
    const toast = useToast();

    const name = ref<string | null>(null);
    const isEnabled = ref<boolean | null>(null);
    const isOpen = ref<boolean | null>(null);
    const sort = ref<string | null>(null);
    const pageNumber = ref(1);
    const pageSize = ref(20);

    name.value = route.query.name ? `${route.query.name}` : null;
    isOpen.value = StringUtils.toBoolean(`${route.query.isOpen}`) ?? null;
    isEnabled.value = StringUtils.toBoolean(`${route.query.isEnabled}`) ?? null;
    pageNumber.value = Number(route.query.pageNumber) || 1;
    pageSize.value = Number(route.query.pageSize) || 10;

    const loading = ref(false);

    const openBranchModal = ref<InstanceType<typeof ConfirmationModal>>();
    const closeBranchModal = ref<InstanceType<typeof ConfirmationModal>>();
    const enableBranchModal = ref<InstanceType<typeof ConfirmationModal>>();
    const disableBranchModal = ref<InstanceType<typeof ConfirmationModal>>();

    const modalBranchName = ref<string | null>(null);
    const modalBranchId = ref<number | null>(null);

    const generateTableHeaders = () => {
      return [
        {
          name: "Id",
          key: "id",
          sortable: true,
        },
        {
          name: I18nUtils.translateGlobal("sea.pages.branches.tblHeaderName"),
          key: "name",
          sortable: true,
        },
        {
          name: I18nUtils.translateGlobal("sea.pages.branches.tblHeaderPhone"),
          key: "phone",
        },
        {
          name: I18nUtils.translateGlobal(
            "sea.pages.branches.tblHeaderAddress"
          ),
          key: "address",
        },
        {
          name: I18nUtils.translateGlobal("sea.pages.branches.tblHeaderOpen"),
          key: "isOpen",
        },
        {
          name: I18nUtils.translateGlobal(
            "sea.pages.branches.tblHeaderEnabled"
          ),
          key: "isEnabled",
        },
        {
          name: I18nUtils.translateGlobal("sea.pages.branches.tblHeaderDate"),
          key: "date",
        },
        {
          name: I18nUtils.translateGlobal(
            "sea.pages.branches.tblHeaderActions"
          ),
          key: "actions",
        },
      ];
    };

    const tableHeaders = ref(generateTableHeaders());

    watch(locale, () => {
      tableHeaders.value = generateTableHeaders();
    });

    const { branchesRef, totalRef, pageNumberRef, pageSizeRef, getBranches } =
      getBranchesRequest();

    const { branchRef, patchBranch } = patchBranchRequest();

    branchesRef.value = branchesRef.value ?? [];

    const onIsOpenChange = async (event) => {
      isOpen.value = StringUtils.toBoolean(event.target.value);
      await getData();
    };

    const onIsEnabledChange = async (event) => {
      isEnabled.value = StringUtils.toBoolean(event.target.value);
      await getData();
    };

    const updateQueries = async () => {
      const newQuery = Object.assign({}, route.query);
      if (name.value && name.value.length > 0) {
        newQuery.name = name.value;
      } else {
        delete newQuery.name;
      }
      if (isOpen.value === true || isOpen.value === false) {
        newQuery.isOpen = isOpen.value.toString();
      } else {
        delete newQuery.isOpen;
      }
      if (isEnabled.value === true || isEnabled.value === false) {
        newQuery.isEnabled = isEnabled.value.toString();
      } else {
        delete newQuery.isEnabled;
      }
      if (pageNumber.value) newQuery.pageNumber = pageNumber.value.toString();
      if (pageSize.value) newQuery.pageSize = pageSize.value.toString();
      await router.replace({ query: newQuery });
    };

    const getData = async () => {
      loading.value = true;

      await updateQueries();
      await getBranches(
        name.value,
        isOpen.value,
        isEnabled.value,
        sort.value,
        pageNumber.value,
        pageSize.value
      );
      reinitializeComponents();

      loading.value = false;
    };

    const openBranch = (id, name) => {
      modalBranchId.value = id;
      modalBranchName.value = name;
      showModal(openBranchModal.value?.vDom);
    };

    const doOpenBranch = async () => {
      loading.value = true;
      await patchBranch(modalBranchId.value ?? 0, { isOpen: true });

      if (branchRef.value) {
        await getData();
        toast.success(
          I18nUtils.translateGlobal(
            "sea.pages.branches.toastTextBranchOpenedSuccess",
            {
              name: modalBranchName.value,
            }
          )
        );
        loading.value = false;
      } else {
        toast.warning(
          I18nUtils.translateGlobal(
            "sea.pages.branches.toastTextBranchOpenedFailure",
            {
              name: modalBranchName.value,
            }
          )
        );
        loading.value = false;
      }
    };

    const closeBranch = (id, name) => {
      modalBranchId.value = id;
      modalBranchName.value = name;
      showModal(closeBranchModal.value?.vDom);
    };

    const doCloseBranch = async () => {
      loading.value = true;
      await patchBranch(modalBranchId.value ?? 0, { isOpen: false });

      if (branchRef.value) {
        await getData();
        toast.success(
          I18nUtils.translateGlobal(
            "sea.pages.branches.toastTextBranchClosedSuccess",
            {
              name: modalBranchName.value,
            }
          )
        );
        loading.value = false;
      } else {
        toast.warning(
          I18nUtils.translateGlobal(
            "sea.pages.branches.toastTextBranchClosedFailure",
            {
              name: modalBranchName.value,
            }
          )
        );
        loading.value = false;
      }
    };

    const enableBranch = (id, name) => {
      modalBranchId.value = id;
      modalBranchName.value = name;
      showModal(enableBranchModal.value?.vDom);
    };

    const doEnableBranch = async () => {
      loading.value = true;
      await patchBranch(modalBranchId.value ?? 0, { isEnabled: true });

      if (branchRef.value) {
        await getData();
        toast.success(
          I18nUtils.translateGlobal(
            "sea.pages.branches.toastTextBranchEnabledSuccess",
            {
              name: modalBranchName.value,
            }
          )
        );
        loading.value = false;
      } else {
        toast.warning(
          I18nUtils.translateGlobal(
            "sea.pages.branches.toastTextBranchEnabledFailure",
            {
              name: modalBranchName.value,
            }
          )
        );
        loading.value = false;
      }
    };

    const disableBranch = (id, name) => {
      modalBranchId.value = id;
      modalBranchName.value = name;
      showModal(disableBranchModal.value?.vDom);
    };

    const doDisableBranch = async () => {
      loading.value = true;
      await patchBranch(modalBranchId.value ?? 0, { isEnabled: false });

      if (branchRef.value) {
        await getData();
        toast.success(
          I18nUtils.translateGlobal(
            "sea.pages.branches.toastTextBranchDisabledSuccess",
            {
              name: modalBranchName.value,
            }
          )
        );
        loading.value = false;
      } else {
        toast.warning(
          I18nUtils.translateGlobal(
            "sea.pages.branches.toastTextBranchDisabledFailure",
            {
              name: modalBranchName.value,
            }
          )
        );
        loading.value = false;
      }
    };

    const sortBranches = async (order) => {
      if (order) {
        sort.value = `${order.columnName},${order.order}`;
        await getData();
      }
    };

    const updatePageSize = async (sizePerPage) => {
      pageSize.value = sizePerPage;
      await getData();
    };

    const updatePageNumber = async (numberOfPage) => {
      pageNumber.value = numberOfPage;
      await getData();
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs("sea.pages.branches.title", [
        "sea.aside.categories.management.title",
      ]);
      await getData();
    });

    return {
      I18nUtils,
      UserUtils,
      DateTimeUtils,
      name,
      isOpen,
      onIsOpenChange,
      isEnabled,
      onIsEnabledChange,
      tableHeaders,
      getData,
      loading,
      branchesRef,
      totalRef,
      pageNumberRef,
      pageSizeRef,
      sortBranches,
      updatePageSize,
      updatePageNumber,
      showModal,
      modalBranchName,
      openBranchModal,
      doOpenBranch,
      openBranch,
      closeBranchModal,
      doCloseBranch,
      closeBranch,
      enableBranchModal,
      enableBranch,
      doEnableBranch,
      disableBranchModal,
      disableBranch,
      doDisableBranch,
    };
  },
});
