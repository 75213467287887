import { Modal } from "bootstrap";

const showModal = (modalEl: HTMLElement | null | undefined): void => {
  if (!modalEl) {
    return;
  }

  const myModal = new Modal(modalEl);
  myModal.show();
};

const hideModal = (modalEl: HTMLElement | null | undefined): void => {
  if (!modalEl) {
    return;
  }

  const myModal = Modal.getInstance(modalEl);
  myModal.hide();
};

export { showModal, hideModal };
